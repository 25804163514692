function link_is_external(link_element) {
	return link_element.host !== window.location.host;
}

function equal_heights(target) {
	target.css('height', 'auto');
	var heights = new Array();
	target.each(function (event) {
		heights.push($(this).outerHeight());
	});
	var tallest = Math.max.apply(Math, heights);
	target.outerHeight(tallest);
}

function toggle_aria_expanded(toggle_element) {
	if ($(toggle_element).attr('aria-expanded') == 'true') {
		$(toggle_element).attr('aria-expanded', 'false');
	} else {
		$(toggle_element).attr('aria-expanded', 'true');
	}
}

$(function () {
	$('.cell a').each(function () {
		if (link_is_external(this)) {
			$(this).attr('target', '_blank');
		}
	});

	$(window).load(function () {
		$('.media-mentions .cell').css('height', 'auto');
		equal_heights($('.media-mentions .cell'));
	});

	$(window).resize(function () {
		equal_heights($('.media-mentions .cell'));
	});

	// Toggle News category nav
	$('.news-categories__trigger').click(function (event) {
		if (localStorage.getItem('newsCategoriesNav') == 1) {
			localStorage.setItem('newsCategoriesNav', 0);
		} else {
			localStorage.setItem('newsCategoriesNav', 1);
		}

		$(this).toggleClass('open');
		$('#news-categories__content').slideToggle();
		toggle_aria_expanded($('#news-categories__content'));
	});

	// Hide News category nav on page load if it was previously closed
	if (localStorage.getItem('newsCategoriesNav') == 0) {
		$('#news-categories__content').hide();
		$('.news-categories__trigger').removeClass('open');
		$('#news-categories__content').attr('aria-expanded', 'false');
	}
});
